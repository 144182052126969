* {
  padding: 0px;
  margin: 0px;
  font-family: "ROBOTO", sans-serif;
}

body {
  padding: 0px;
  margin: 0px;
}

.inputBorder {
  border: 0.5px, solid #314f5371;
}

#root {
  padding: 0px;
  margin: 0px;
}

.primary-bg {
  background-color: #00C6D4;
}

#primarybg {
  background-color: #00929c;
}

.primarytext {
  color: #00a0ac;
}

.money-color {
  color: #76B404;
}

.money-bg {
  background-color: #76B404;
}

#primarybtn {
  background-color: #00C6D4;
  color: rgb(255, 255, 255);
}

#primary-btn-out {
  background-color: #ffffff;
  color: #00C6D4;
  border: 0.6px solid #00C6D4;
}

#primary-btn-out:hover {
  background-color: #00C6D4;
  color: #ffffff;
  border: 0.5px solid #c9fbff;
}

#primarybtn:hover {
  background-color: #00a5b1;
  color: rgb(255, 255, 255);
}

.primary-btn-bg {
  background-color: #00C6D4;
}

.primary-btn-bg:hover {
  background-color: #00abb8;
}

.primary-text {
  color: #00C6D4;
}

#link {
  color: #00a5b1;
}

#accordian-header {
  background-color: #2abe80;
}

.secondary-color {
  color: #d1d1d1
}

.secondary-bg {
  background-color: #d1d1d1;
}

.gradient {
  background-image: linear-gradient(to right, #4af3ff, #00c6d4);
}

.license {
  width: 80px;
  height: 80px;
  z-index: 1000;
}

.space {
  margin-top: -50px;
  overflow: visible;
}

.features {
  width: 120px;
  height: 120px;
}

.fcard {
  width: 80px;
  height: 80px;
}

.faq {
  width: 90px;
  height: 90px;
}

.contact {
  width: 70px;
  height: 70px;
}

.nav-link.active {
  font-weight: bold;
}

.nav-link {
  font-size: 1.05rem;
}

.get:hover {
  letter-spacing: 0.1ch;
  color: #00c6d4;
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
}

.primary-bg {
  color: #00c6d4;
}

.primary-fill {
  background-color: #00c6d4;
}

.primary-text {
  color: #00c6d4;
}

.btncolor {
  background-color: #00c6d4;
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 0.5vh;
  font-size: 1.0rem;
}

#btnnn {
  background-color: #00c6d4;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.0rem;
}

.colored2 {
  background-color: #e2ffff;
  background-image: url("./assets/surfieethiopia.png");
  background-repeat: no-repeat, repeat;
  background-color: #cccccc;
}

.colored {
  background-color: hsl(184, 100%, 42%, 0.85);
}

.textcolor-w {
  color: aliceblue;
}

.textcolor-c {
  color: #afe0e4;
}

.textcolor-ct {
  background-color: rgb(255, 255, 255, 0.2);
}

.cardcol {
  background-color: #ffffff;
}

.image_wrap {
  margin: 0px 30px;
  width: 100px;
  filter: grayscale(100%);
  transition: filter 0.25s;
}

.image_wrap:hover {
  filter: grayscale(0%);
}

.pointer {
  cursor: pointer;
}

.copyright {
  background-color: #037780;
  font-size: 80%;
}

.fot {
  background-color: #00c6d488;
  align-items: center;
  justify-content: center;
}

.con:hover {
  background-color: #faffff;
  letter-spacing: 0.2ch;
  color: #1896af;
  border: 50%;
}

.con {
  background-color: #faffff;
  letter-spacing: 0.2ch;
  color: #1896af;
}

.just {
  text-align: justify;
}

.over {
  letter-spacing: 0.1ch;
  color: #023438;
  box-shadow: 0 5px 15px rgba(181, 236, 250, 0.4);
}

.btnn {
  border: none;
  margin-left: 0%;
}

.btnn:hover {
  background-color: #00c6d4;
}

.bg {
  background-color: darkgrey;
}

.bg:hover {
  background-color: darkgrey;
}


/* Heero image */

#frontimage {
  background-image: url("./assets/bg.png");
  background-color: #00eeff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 99.1vw;
  height: 100%;
}

.colored1 {
  background-color: #eff7f7;
}

.content1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  color: #FFFFFF;
}

.content2 {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  color: #FFFFFF;
}

.button {
  letter-spacing: 0.1ch;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
}

#find {
  text-decoration: none;
  color: #00c6d4;
}

.mina {
  height: 40px;
}

.p2 {
  width: 130px;
  height: 120px;
}

.blur {
  backdrop-filter: blur(6px);
}

@media screen and (min-width:10px) and (max-width:280px) {
  #image {
    display: none;
  }
}

@media screen and (min-width:280px) and (max-width:480px) {
  #getstartedbtn {
    display: flex;
    align-content: center;
    width: 40%;
    text-align: center,
  }
  #homepage {
    opacity: 1;
    padding-top: 20;
    align-items: center;
    justify-content: center;
  }
  #emptycloumn {
    display: none;
  }
  .content1 {
    left: 20px;
    font-size: 2vh;
    font-size: 3vw;
    top: 9vh;
    width: 90vw;
    line-height: normal;
  }
  .content2 {
    left: 20px;
    margin-top: 4px;
    font-size: 3vh;
    font-size: 7vw;
    width: 90vw;
    top: 15vh;
    line-height: normal;
  }
  #getbutton {
    display: none;
  }
  #sponsor {
    width: 20vw;
  }
  #image {
    display: none;
  }
  #card {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .alignme {
    text-align: justify;
  }
  .reverseit {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width:480px) and (max-width:767px) {
  .content1 {
    left: 20px;
    font-size: 4vh;
    font-size: 3vw;
    width: 90vw;
    top: 12vh;
    line-height: normal;
  }
  .content2 {
    left: 20px;
    margin-top: 4px;
    font-size: 3vh;
    font-size: 7vw;
    width: 90vw;
    top: 17vh;
    line-height: normal;
  }
  #getbutton {
    display: none;
  }
  #sponsor {
    width: 20vw;
  }
  #image {
    display: none;
  }
  #card {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

@media screen and (min-width:768px) and (max-width:1024px) {
  .content1 {
    left: 30px;
    font-size: 2vh;
    font-size: 2vw;
    width: 90vw;
    top: 12vh;
    line-height: normal;
  }
  .content2 {
    left: 30px;
    margin-top: 4px;
    font-size: 2vh;
    font-size: 6vw;
    width: 90vw;
    top: 19vh;
    line-height: normal;
  }
  .button {
    top: 15vh;
  }
  #image {
    display: none;
  }
  #card {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .hid {
    display: none;
  }
}

@media screen and (min-width:1024) and (max-width:1490px) {
  .hid {
    display: none;
  }
}