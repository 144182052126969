@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Popins", sans-serif;
}

ul {
  list-style-type: none;
}